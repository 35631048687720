import { template as template_6015cf198a3f41a7a304ba2d7ea4e990 } from "@ember/template-compiler";
const FKLabel = template_6015cf198a3f41a7a304ba2d7ea4e990(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_17e0da0f73a240a7bad1b4b08ed3093e } from "@ember/template-compiler";
const SidebarSectionMessage = template_17e0da0f73a240a7bad1b4b08ed3093e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

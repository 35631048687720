import { template as template_fbac5ba025be409cb59cb47d9f1de525 } from "@ember/template-compiler";
const FKText = template_fbac5ba025be409cb59cb47d9f1de525(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

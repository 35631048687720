import { template as template_2b83ca3ac6c2489e99f9b079c23f4741 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2b83ca3ac6c2489e99f9b079c23f4741(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;

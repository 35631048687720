import { template as template_07d5b5f419454e04a675348cbc434837 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_07d5b5f419454e04a675348cbc434837(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
